const NotificationActionButton = ({ label, action, disabled = false }) => {

  return (
    <button className="grow shrink basis-0 h-8 px-3 py-2 bg-[#156fee] rounded justify-center items-center flex text-white text-sm font-medium leading-tight" disabled={disabled} onClick={action}>
      {label}
    </button>
  );
};

export default NotificationActionButton;
