import { useNavigate } from 'react-router-dom';
import NotificationActionButton from './NotificationActionButton';
import { formatAgoDate } from '../../utils/formatDate';
import NotificationMenu from './NotificationMenu';

const StatusChangeNotification = ({ userNotification, isSeen, clear, handleSeen  }) => {
  const navigate = useNavigate();
  const { notification, createdAt } = userNotification;

  return (
    <div className={`p-6 ${isSeen ? "bg-gray-50": "bg-[#edf3ff]"} justify-start items-start gap-3 flex group`}>
      <div className="grow shrink basis-0 self-stretch flex-col justify-center items-start gap-2 flex">
        <div className="self-stretch">
          <span className="text-slate-700 text-sm font-semibold leading-tight uppercase">
            {notification.approvalRequestID.approvalTitle}
          </span>
          <span className="text-slate-700 text-sm font-normal leading-tight">
            {' '}
            {notification.message}
          </span>
        </div>
        <div className="min-w-[121px] py-1 justify-start items-start gap-2 inline-flex hidden group-hover:visible">
          <NotificationActionButton
            label="View approval"
            action={() =>
              navigate(`/review?aprReqID=${notification.approvalRequestID._id}`)
            }
          />
        </div>
      </div>
      <div className="flex-col justify-center items-center flex">
        <div className="text-slate-600 text-xs font-normal leading-[18px]">
          {formatAgoDate(createdAt)}
        </div>
        <NotificationMenu seen={isSeen} clear={clear} handleSeen={handleSeen} userNotification={userNotification}/>
      </div>
    </div>
  );
};

export default StatusChangeNotification;
