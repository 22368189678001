import { createRef, useEffect, useState } from 'react';
import searchIcon from '../../icons/search-icon.png';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { userAtom } from '../../recoil/userAtoms';
import { workspacesAtom } from '../../recoil/workspaceAtom';
import { useToast } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

const GlobalSearch = () => {
  const [workspaces] = useRecoilState(workspacesAtom);
  const [user] = useRecoilState(userAtom);
  const [approvalRequests, setApprovalRequests] = useState([]);
  const [searchRecords, setSearchRecords] = useState({});
  const [search, setSearch] = useState('');
  const toast = useToast()
  const searchRef = createRef(null)

  const navigate = useNavigate()

  const handleSearch = async () => {
    if (search === '' || search.length < 3) {
      toast({
        title: search ? "Search query must contain at least 3 characters":"Search field is empty",
        status: "error",
        duration: 3000,
        isClosable: true,
      })
      return
    }

    if (searchRecords[search]) {
      setApprovalRequests(searchRecords[search]);
      return; 
    }

    // search for the request
    const workspaceIds = workspaces.map(workspace => `workspaceIds=${workspace._id}`).join('&');
    const result = await axios.get(`/api/approvalRequests/search?q=${search}&${workspaceIds}&firebaseAuthUUID=${user.firebaseAuthUUID}`)

    if (result.data.records.length === 0) {
      toast({
        title: "No records found",
        status: "info",
        duration: 3000,
        isClosable: true,
      })
    }


    setApprovalRequests(result.data.records);
    setSearchRecords({
      ...searchRecords,
      [search]: result.data.records
    })

    setTimeout(() => {
      const rec = { ...searchRecords };

      delete rec[search];

      setSearchRecords({
        ...searchRecords,
      })
    }, 1000 * 60); // 5 minutes
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  }

  const handleSearchInputChange = (e) => {
    setSearch(e.target.value);
    setApprovalRequests(searchRecords[e.target.value] || []);
  }

  useEffect(() => {
    const eventHandler = (e) => {
      if (searchRef.current && !searchRef.current.contains(e.target)) {
        setApprovalRequests([]);
        setSearch('');
      }
    }
    window.addEventListener('click',eventHandler)

    return () => {
      window.removeEventListener('click', eventHandler)
    }
  }, [])


  return (
    <div className="max-w-[836px] w-full box-shadow flex flex-col gap-3 relative" ref={searchRef}>
      <div className="h-10 px-3.5 py-2.5 border border-gray-300 flex gap-2 rounded-lg max-w-[536px] w-full">
        <div className="w-5"
          onClick={handleSearch}
        >
          <img src={searchIcon} />
        </div>
        <input
          type="text"
          placeholder="Search"
          className="w-full outline-none"
          value={search}
          onChange={handleSearchInputChange}
          onKeyDown={handleKeyDown}
        />
      </div>
      {
        approvalRequests.length !== 0 ? (
          <div className="absolute top-full pt-2 w-full max-w-[836px] z-30 bg-white max-h-[400px] rounded-lg flex">
          <ol className="w-full border rounded-lg grow p-1.5 flex flex-col gap-2 overflow-y-scroll no-scrollbar">
            {
              approvalRequests.map(request => (
                <li key={request._id} className="bg-gray-50 rounded p-1 hover:bg-gray-200 cursor-pointer" onClick={() => navigate(`/review?aprReqID=${request?._id}`)}>
                  <div>
                    <h3 className="flex gap-2 items-baseline justify-between">
                      <span className="font-semibold text-sm">{request.approvalTitle}</span>
                      <span className="text-xs text-gray-500">Author: {request.user._id == user._id ? "You": request.user.fullName}</span>
                    </h3>
                    <p className="text-xs" dangerouslySetInnerHTML={
                      { __html: request.description }
                    }></p>
                  </div>
                </li>
              ))
            }
          </ol>
        </div>
        ) : null
      }
 
    </div>
  );
};

export default GlobalSearch;
