import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import { userAtom } from '../recoil/userAtoms';
import Sidebar from '../components/Sidebar';
import { Link } from 'react-router-dom';
import notifIcon2 from '../icons/notifIcon2.png';
import { useToast } from '@chakra-ui/react';
import formatDate from '../utils/foramtDateComments';
import { Logger } from '../utils/logger';
import PageWrapper from '../components/PageWrapper';
import CommentNotification from '../components/Notifications/CommentNotification';
import NewFeatureNotification from '../components/Notifications/NewFeatureNotification';
import ApprovalRequestNotification from '../components/Notifications/ApprovalRequestNotification';
import MemberApprovalRequestNotification from '../components/Notifications/MemberApprovalRequestNotification';
import NotificationItem from '../components/Notifications/NotificationItem';


const NotificationList = () => {
  const [activeTab, setActiveTab] = useState('all');

  const [user] = useRecoilState(userAtom);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const fetchNotifications = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(
        `/api/notifications/get/${user.firebaseAuthUUID}`
      );
      setNotifications(res.data);

      Logger.log('Notifications', res.data);
      
    } catch (error) {
      Logger.error(error);
    }
    setIsLoading(false);
  };

  const setAllNotificationsToSeen = async () => {
    try {
      const notificationIds = notifications.map((n) => n._id);
      await axios.post('/api/notifications/markAsSeen', { notificationIds });
    } catch (error) {
      Logger.error(error);
    }
  };



  const clearNotification = async (notificationId) => {
    try {
      await axios.post('/api/notifications/clearNotification', {
        notificationId,
      });
      fetchNotifications();
    } catch (error) {
      Logger.error(error);
    }
  };

  const clearAllNotifications = async () => {
    Logger.log('clearing...');
    try {
      const notificationIds = notifications.map((n) => n._id); // Extract notification IDs
      await axios.post('/api/notifications/clearMultipleNotifications', {
        notificationIds,
      });
      fetchNotifications(); // Refresh the notifications list
      toast({
        title: 'All notifications cleared.',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      Logger.error(error);
      toast({
        title: 'An error occurred.',
        description: 'Unable to clear notifications.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    fetchNotifications();
    // setAllNotificationsToSeen();
  }, []);

  return (
    <PageWrapper title="Notifications">
      <div className="flex flex-col grow gap-5 overflow-hidden">
        <div className="mt-3 flex justify-between md:h-9 items-center flex-col md:flex-row">
          <div className="text-3xl font-semibold">Notifications</div>

        </div>
        <div className="flex gap-3 cursor-pointer">
          <div
            className={`px-3 border-primary min-w-20 justify-center flex pb-2 ${
              activeTab == 'all' ? 'border-b-[4px]' : ''
            }`}
            onClick={() => setActiveTab('all')}
          >
            All
          </div>
          {/* <div
            className={`px-3 border-primary min-w-20 justify-center flex pb-2 ${
              activeTab == 'mentions' ? 'border-b-[4px]' : ''
            }`}
            onClick={() => setActiveTab('mentions')}
          >
            Mentions
          </div> */}
        </div>
        <div className="flex flex-col flex-grow overflow-y-auto no-scrollbar gap-1.5">
          {
            notifications.length ? (
              notifications.map((notification) => (
                <NotificationItem
                  key={notification._id}
                  notification={notification}
                />
              ))
            ) : (
              <div className="text-center mt-10">No notifications</div>
            )
          }
          {/* <MemberApprovalRequestNotification />
          <CommentNotification />
          <NewFeatureNotification />
          <ApprovalRequestNotification /> */}
        </div>
      </div>
    </PageWrapper>
  );
};

export default NotificationList;
