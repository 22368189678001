import FormDropSelect from '../Form/FormDropSelect';
import FormInput from '../Form/FormInput';
import ModalContent from './ModalContent';

const ApprovalFilterModal = ({ show, handleClose, labels, filterOptions, handleFilterSelect, handleFilter, clearFilter }) => {
  return (
    <ModalContent
      title={'Filter'}
      className={'max-w-[480px] w-full'}
      closeModal={handleClose}
      openModal={show}
    >
      <div className="flex flex-col gap-2 text-sm w-full gap-6">
        <p>Select filters, date ranges, and formats</p>

        <FormDropSelect
          label="Approval Status"
          placeholder="Select approval status"
          options={[
            {
              value: 'all',
              label: 'All',
            },
            {
              value: 'pending',
              label: 'Pending',
            },
            {
              value: 'approved',
              label: 'Approved',
            },
            {
              value: 'rejected',
              label: 'Rejected',
            },
            {
              value: 'needsRevison',
              label: 'Needs Revision',
            },
            {
              value: 'resolved',
              label: 'Resolved',
            },
          ]}
          values={filterOptions.status}
          setValue={handleFilterSelect('status')}
          isMulti={true}
        />
        <FormDropSelect
          label="Overdue"
          placeholder="Select overdue"
          options={[
            {
              value: 'all',
              label: 'All',
            },
            {
              value: 'yes',
              label: 'Yes',
            },
            {
              value: 'no',
              label: 'No',
            }
          ]}
          values={filterOptions.overdue}
          setValue={handleFilterSelect('overdue')}
          isMulti={true}
        />
        <FormDropSelect
          label="Label"
          placeholder="Select label"
          options={[
            {
              value: 'all',
              label: 'All',
            },
            ...labels.map((label) => ({
              value: label,
              label: label,
            })),
          ]}
          values={filterOptions.label}
          setValue={handleFilterSelect('label')}
          isMulti={true}
        />
        <FormInput
          label="Creation Date"
          type="date"
          placeholder="Select date"
          value={filterOptions.createdAt}
          onChange={(e) => handleFilterSelect('createdAt')(e.target.value)}
        />
        <FormInput label="Deadline" type="date" placeholder="Select date" value={filterOptions.due} onChange={(e) => handleFilterSelect('due')(e.target.value)}/>
        <div className="flex flex-col justify-between w-full gap-4 mt-8 mb-4">
          <button className="bg-primary text-white rounded-lg py-2.5 px-5 w-full" onClick={handleFilter}>
            Filter
          </button>
          <button className="border rounded-lg py-2.5 px-5 w-full" onClick={clearFilter}>
            Clear Filter
          </button>
        </div>
      </div>
    </ModalContent>
  );
};

export default ApprovalFilterModal;
