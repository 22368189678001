import React, { useState } from 'react';
import AWS from 'aws-sdk';
import uploadToS3 from '../../utils/uploadToS3';
import ModalContent from '../Modal/ModalContent';
import AttachFile from '../SVGs/AttachFile';
import { useRecoilValue } from 'recoil';
import { userAtom } from '../../recoil/userAtoms';
import { Logger } from '../../utils/logger';

const fileTypeMap = {
  'image/jpeg': 'image',
  'image/png': 'image',
  'video/mp4': 'video',
  'image/jpg': 'image',
  'image/gif': 'image',
}

const CommentAttachment = ({ attachments, addAttachments }) => {
  const uploadRef = React.createRef(null);
  const [imageData, setImageData] = useState([]);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [totalFileSize, setTotalFileSize] = useState(0);
  const user = useRecoilValue(userAtom);
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRETE_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });
  const s3 = new AWS.S3();

  const moveVideoToBucket = async (videoUrl, targetBucket, type = 'video') => {
    // Extract the bucket name and key from the URL
    const sourceBucket = videoUrl.split('.s3.')[0].split('://')[1];
    const key = videoUrl.split('.com/')[1];

    const { ContentLength } = await s3
      .headObject({
        Bucket: sourceBucket,
        Key: key,
      })
      .promise();
    const fileSizeMB = ContentLength / (1024 * 1024); // Convert size to MB
    Logger.log('fileSizeMB ', fileSizeMB);

    // Copy the object to the new bucket
    await s3
      .copyObject({
        Bucket: targetBucket,
        CopySource: `${sourceBucket}/${key}`,
        Key: key,
      })
      .promise();

    // Delete the original object
    await s3
      .deleteObject({
        Bucket: sourceBucket,
        Key: key,
      })
      .promise();

    // Return the new URL and size
    return {
      type,
      url: `https://${targetBucket}.s3.${AWS.config.region}.amazonaws.com/${key}`,
      size: fileSizeMB, // Include the size in the return object
    };
  };

  const processFiles = async (types) => {
    const newVideoUrls = [];
    let additionalSizeMB = 0; // Initialize additional size

    // Process image files
    const imagesNew = await uploadToS3(
      imageData?.filter((f) => types.includes(f.type)),
      user,
      'attachments'
    );

    additionalSizeMB += imagesNew.reduce((acc, curr) => acc + curr.size, 0);

    // Move video files to a different bucket and accumulate sizes
    for (const file of imageData?.filter((f) => f.type === 'video')) {
      const moveResult = await moveVideoToBucket(file.data, 'swiftapprove-1');
      additionalSizeMB += moveResult.size; // Accumulate video file sizes
      newVideoUrls.push(moveResult);
    }

    // Now update the totalFileSize state once with the total additional size
    // Logger.log(additionalSizeMB);
    // setTimeout(() => {
    //   setTotalFileSize(additionalSizeMB);
    // }, 1000); // 1000 milliseconds = 1 second

    // Output the results
    const combinedUrls = imagesNew.concat(newVideoUrls);
    return [combinedUrls, additionalSizeMB];
  };

  const handleChange = (e) => {
    const files = e.target.files;
    const newFiles = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onloadend = () => {
        newFiles.push({
          data: reader.result,
          type: fileTypeMap[file.type],
          name: file.name,
        });
        setImageData(newFiles);
      };
      reader.readAsDataURL(file);
    }

    setOpenUploadModal(true);
  };

  const handleAttachment = () => {
    setProcessing(true);

    processFiles(['image', 'video']).then(([urls, size]) => {
      
      Logger.log(urls, size);
      // Add the attachments to the comment
      addAttachments(urls);
      setProcessing(false);
      setOpenUploadModal(false);
      setImageData([]);
  }).catch((err) => {
    Logger.error(err);
    setProcessing(false);
  })

    ;
  };

  return (
    <div
      className="flex justify-center items-center w-5 h-5 cursor-pointer group/attachment"
    >
      <span
        onClick={() => {
          uploadRef.current.click();
        }}
        className="relative"
      >
        <AttachFile className={'stroke-primary-default'} />
        <span className='text-[8px] absolute -right-0 -bottom-2 text-primary-default'>{attachments.length || null}</span>

      {attachments.length ? <div className='absolute bottom-full text-[9px] flex flex-col w-fit border rounded invisible group-hover/attachment:visible'>
        Remove attachments
      </div>: null}
      </span>
      {/* </div> */}
      <input type="file" hidden ref={uploadRef} onChange={handleChange} />


      <ModalContent
        openModal={openUploadModal}
        closeModal={() => setOpenUploadModal(false)}
        title={'Attach Files'}
      >
        <div className='flex flex-col gap-2 pt-3'>

          <p>
            Do you want to attach selected files?
          </p>
          <div className='flex justify-between'>
            <button className='px-2 py-1 rounded border text-error-500' onClick={() => setOpenUploadModal(false)}>Cancel</button>
            <button className='px-2 py-1 rounded bg-primary text-white' onClick={handleAttachment}>Attach</button>
          </div>
        </div>
      </ModalContent>
    </div>
  );
};

export default CommentAttachment;
