import React, { useEffect, useState } from 'react';
import ChevronDown from '../SVGs/ChevronDown';
import CheckboxWithTick from '../CheckBox';
import Plus from '../SVGs/Plus';

const FormDropSelect = ({
  label,
  id,
  type,
  options = [],
  placeholder = 'Select an option',
  error,
  hint,
  value = '' || [],
  values = [],
  setValue = () => {},
  handleAddLabel,
  isMulti = false,
  ...otherProps
}) => {
  const [newLabel, setNewLabel] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const formDropSelectRef = React.createRef();

  const handleChange = (e) => {
    setNewLabel(e.target.value);
  };

  const handleEnter = (e) => {
    if (e.key === 'Enter') {
      if (newLabel) {
        handleAddLabel && handleAddLabel({ label: newLabel, value: newLabel });
        setValue && setValue(newLabel);
        setNewLabel('');
        setIsOpen(false);
      }
    }
  };

  const handleSelect = (selectedValue) => {
    if (isMulti) {
      const idx = values.findIndex((val) => val == selectedValue);

      if (idx < 0) {
        setValue([...values, selectedValue]);
      } else {
        setValue(values.filter((val) => val != selectedValue));
      }
    } else {
      setValue(selectedValue);
    }

    setIsOpen(false);
  };

  const handleOpenDropSelect = (isOpen) => {
    setIsOpen(isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isOpen &&
        formDropSelectRef?.current &&
        !formDropSelectRef?.current.contains(event.target) &&
        !event.target.classList.value.includes('form-drop-select')
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen, formDropSelectRef]);

  return (
    <div
      className="flex flex-col gap-1.5 w-full relative form-drop-select"
      ref={formDropSelectRef}
    >
      <label htmlFor={id} className="">
        {label}
      </label>
      <div className="relative">
        <div
          className="flex items-center rounded-lg py-2.5 px-3.5 w-full border shadow-sm h-11 bg-white justify-end relative cursor-pointer"
          onClick={() => handleOpenDropSelect(!isOpen)}
        >
          <div
            className={`flex justify-start grow ${
              !value || !values.length ? 'text-gray-500' : ''
            }`}
          >
            {/* {options.find((option) => option.value == value || values.includes(option.value))?.label ??
              placeholder} */}
            {(isMulti
              ? values
                  ?.map(
                    (val) =>
                      options.find((option) => option.value == val)?.label
                  )
                  ?.join(', ')
              : options.find((option) => option.value == value)?.label) ||
              placeholder}
          </div>
          <ChevronDown />
        </div>
        {isOpen && (
          <div
            className={
              'absolute w-full border flex flex-col rounded-lg bg-white text-sm z-30'
            }
          >
            <div className="flex w-full border-b">
              {handleAddLabel && (
                <input
                  className="w-full outline-none p-2"
                  onChange={handleChange}
                  onKeyDown={handleEnter}
                  value={newLabel}
                  placeholder="Add new label"
                  maxLength={20}
                />
              )}
            </div>
            <div className="max-h-40 overflow-y-scroll no-scrollbar">
              {options.map((option, index) => (
                <div
                  key={index}
                  className={`flex w-full justify-between p-2 hover:bg-gray-100 cursor-pointer ${
                    (
                      isMulti
                        ? values.includes(option.value)
                        : value === option.value
                    )
                      ? 'bg-gray-200'
                      : ' '
                  }`}
                  onClick={() => handleSelect(option.value)}
                >
                  <div>{option.label}</div>
                  <CheckboxWithTick
                    isSelected={
                      isMulti
                        ? values.includes(option.value)
                        : value === option.value
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {hint && (
        <p className={`text-xs ${error ? 'text-red-600' : 'text-gray-600'}`}>
          {hint}
        </p>
      )}
    </div>
  );
};

export default FormDropSelect;
