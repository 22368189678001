const AttachFile = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.70833 12.8332C5.81389 12.8332 5.05556 12.5221 4.43333 11.8998C3.81111 11.2776 3.5 10.5193 3.5 9.62484V3.49984C3.5 2.85817 3.72847 2.30887 4.18542 1.85192C4.64236 1.39498 5.19167 1.1665 5.83333 1.1665C6.475 1.1665 7.02431 1.39498 7.48125 1.85192C7.93819 2.30887 8.16667 2.85817 8.16667 3.49984V9.0415C8.16667 9.44984 8.02569 9.79498 7.74375 10.0769C7.46181 10.3589 7.11667 10.4998 6.70833 10.4998C6.3 10.4998 5.95486 10.3589 5.67292 10.0769C5.39097 9.79498 5.25 9.44984 5.25 9.0415V3.49984H6.125V9.0415C6.125 9.20678 6.1809 9.34532 6.29271 9.45713C6.40451 9.56893 6.54306 9.62484 6.70833 9.62484C6.87361 9.62484 7.01215 9.56893 7.12396 9.45713C7.23576 9.34532 7.29167 9.20678 7.29167 9.0415V3.49984C7.29167 3.0915 7.15069 2.74637 6.86875 2.46442C6.58681 2.18248 6.24167 2.0415 5.83333 2.0415C5.425 2.0415 5.07986 2.18248 4.79792 2.46442C4.51597 2.74637 4.375 3.0915 4.375 3.49984V9.62484C4.375 10.2665 4.60347 10.8158 5.06042 11.2728C5.51736 11.7297 6.06667 11.9582 6.70833 11.9582C7.35 11.9582 7.89931 11.7297 8.35625 11.2728C8.81319 10.8158 9.04167 10.2665 9.04167 9.62484V3.49984H9.91667V9.62484C9.91667 10.5193 9.60556 11.2776 8.98333 11.8998C8.36111 12.5221 7.60278 12.8332 6.70833 12.8332Z"
      fill="#526A8A"
    />
  </svg>
);

export default AttachFile;