import { border } from '@chakra-ui/react';
import mapStatus from '../utils/formatApprovalRequestStatus';
import DotIcon from './SVGs/Dot';

const ApprovalStatus = ({ status, withBorder = false }) => {
  const mappedStatus = mapStatus(status);

  const statusClassNames =
    mappedStatus === 'Feedback Received'
      ? `bg-processing-50 text-processing-500 ${withBorder && "border border-processing-200"}` // Light red for Feedback Received
      : mappedStatus === 'Approved'
      ? `bg-success-50 text-success-500 ${withBorder && "border border-success-200"}`
      : mappedStatus === 'Rejected'
      ? `bg-error-50 text-error-500 ${withBorder && "border border-error-200"}` // Light green for Approved
      : `bg-warning-50 text-warning-500 ${withBorder && "border border-warning-200"}`; // Default color

  const fillStatusClassName =
    mappedStatus === 'Feedback Received'
      ? 'fill-processing-500' // Light red for Feedback Received
      : mappedStatus === 'Approved'
      ? 'fill-success-500'
      : mappedStatus === 'Rejected'
      ? 'fill-error-500' // Light green for Approved
      : 'fill-warning-500';

  return (
    <div
      className={`flex items-center gap-1.5 rounded-full px-3 w-fit text-center text-sm ${statusClassNames}`}
    >
      {/* <DotIcon className={fillStatusClassName} />  */}
      {mappedStatus}
    </div>
  );
};

export default ApprovalStatus;
