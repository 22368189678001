import { useNavigate } from 'react-router-dom';
import NotificationActionButton from './NotificationActionButton';
import { formatAgoDate } from '../../utils/formatDate';
import NotificationMenu from './NotificationMenu';

const CommentNotification = ({
  userNotification,
  isSeen,
  clear,
  handleSeen,
}) => {
  const navigate = useNavigate();
  const { notification, createdAt } = userNotification;
  return (
    <div
      className={`p-6 ${
        isSeen ? 'bg-gray-50' : 'bg-[#edf3ff]'
      } justify-start items-start gap-3 flex group`}
    >
      <div className="w-12 h-12 relative bg-slate-200 rounded-full flex flex-col items-center justify-center">
        {!notification.initiator?.url ? (
          <div className="text-[#72839a] text-xl font-semibold leading-[30px] text-center uppercase">
            {notification.initiator?.name?.charAt(0) ??
            notification.initiator?.firstName?.charAt(0) ??
              notification.triggererEmail.charAt(0)}
          </div>
        ) : (
          <img
            className="w-12 h-12 left-0 top-0 absolute rounded-[200px]"
            src={notification.initiator?.url}
          />
        )}
      </div>
      <div className="grow shrink basis-0 self-stretch flex-col justify-center items-start gap-2 flex">
        <div className="self-stretch">
          <span className="text-slate-700 text-sm font-semibold leading-tight">
            {notification.initiator?.name ?? notification.initiator?.firstName ?? notification.triggererEmail}
          </span>
          <span className="text-slate-700 text-sm font-normal leading-tight">
            {' '}
            added a comment on{' '}
          </span>
          <span className="text-slate-700 text-sm font-semibold leading-tight">
            {notification.approvalRequestID?.approvalTitle}:
          </span>
        </div>
        <div className="self-stretch justify-start items-start gap-2.5 inline-flex">
          <div className="w-1 self-stretch bg-slate-200 rounded-[1px]" />
          <div className="grow shrink basis-0 text-slate-600 text-sm font-normal leading-tight">
            “{notification.message}”
          </div>
        </div>
        <div className="min-w-[121px] py-1 justify-start items-start gap-2 inline-flex hidden group-hover:block">
          <NotificationActionButton
            label="View approval"
            action={() => {
              handleSeen();
              navigate(
                `/review?aprReqID=${notification.approvalRequestID._id}`
              );
            }}
          />
        </div>
      </div>
      <div className="flex-col justify-center items-center flex">
        <div className="text-slate-600 text-xs font-normal leading-[18px]">
          {formatAgoDate(createdAt)}
        </div>
        <NotificationMenu
          seen={isSeen}
          clear={clear}
          handleSeen={handleSeen}
          userNotification={userNotification}
        />
      </div>
    </div>
  );
};

export default CommentNotification;
