import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import AWS from 'aws-sdk';
import AddApprovalRequestApprovers from '../components/CreateApprovalRequestSteps/AddApprovalRequestApprovers';
import AddApprovalRequestDetails from '../components/CreateApprovalRequestSteps/AddApprovalRequestDetails';
import PageWrapper from '../components/PageWrapper';
import StepperIndicator from '../components/Stepper/StepperIndicator';
import { userAtom } from '../recoil/userAtoms';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { Logger } from '../utils/logger';
import Plus from '../components/SVGs/Plus';
import NextArrow from '../components/SVGs/NextArrow';
import CloseButton from '../components/CloseButton';
import ReactPlayer from 'react-player';
import PdfPreview from '../components/DocumentViewers/PdfPreview';
import DocPreview from '../components/DocumentViewers/DocPreview';
import NoContent from '../components/ContentViewer/NoContent';
import UploadContentModal from '../components/Modal/UploadContent';
import {
  removeAnchorTagHref,
  scrubIframeDoc,
} from '../utils/remove-anchor-href';
import { validateRequest } from '../utils/validateUtils';
import { useToast } from '@chakra-ui/react';
import ApprovalCreatedModal from '../components/Modal/ApprovalSentModal';
import uploadToS3 from '../utils/uploadToS3';
import { planValidationNewAppReq } from '../utils/checkNewARAgainstPlan';

const CreateApprovalR = () => {
  const workspaceID = new URLSearchParams(window.location.search).get(
    'workspaceID'
  );
  const [user] = useRecoilState(userAtom);
  const [imageData, setImageData] = useState([]);
  const playerWrapperRef = useRef(null);

  const [currentImage, setCurrentImage] = useState(0);
  const category = new URLSearchParams(window.location.search).get('category');
  const sFormat = new URLSearchParams(window.location.search).get(
    'socialFormat'
  );
  const [isDesktopView, setIsDesktopView] = useState(true);

  const history = useNavigate();
  const toast = useToast();

  const [currentData, setCurrentData] = useState(null);

  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openSentModal, setOpenSentModal] = useState(false);

  const [numVideosUploaded, setNumVideosUploaded] = useState(0);
  const [numImagesUploaded, setNumImagesUploaded] = useState(0);
  const [numDocsUploaded, setNumDocsUploaded] = useState(0);
  const [imageDeleted, setImageDeleted] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [approvers, setApprovers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [htmlContents, setHtmlContents] = useState([]);
  const [URL, setURL] = useState('');
  const [platform, setPlatform] = useState(new URLSearchParams(window.location.search).get('platform') ?? '');
  const [socialFormat, setSocialFormat] = useState('');
  const [description, setDescription] = useState('');
  const [stepOneData, setStepOneData] = useState({
    title: '',
    dueDate: '',
    description: '',
    caption: '',
    link: '',
    imageUrl: {},
    selectedApprovers: [],
    workspaceID,
    label: '',
    autoApprove: false,
    userID: user?._id,
    workspaceMembers: members,
    platform,
    category,
    socialFormat,
    firebaseAuthUUID: user?.firebaseAuthUUID,
    autoApproveDays: 7,
    // totalFileSize: imData[1],
  });

  const [labelOptions, setLabelOptions] = useState([
    { label: 'Urgent', value: 'Urgent' },
    { label: 'Important', value: 'Important' },
    { label: 'Normal', value: 'Normal' },
  ]);

  const [step, setStep] = useState(1);

  const handleNextAction = () => {
    setStep(step + 1);
  };

  const handleStepChange = (step) => {
    if (isSubmitting) return;

    setStep(step);
  };

  const handleDotClick = (index) => {
    setCurrentImage(index);
  };

  const fetchWorkspaceMembers = async () => {
    try {
      // setIsLoading(true); // Assuming you have this function to set loading state

      const res = await axios.get(
        `/api/workspace/getWorkspaceMembers?workspaceID=${workspaceID}`
      );
      const data = res.data;

      // Assuming each member object contains an 'email' field
      let _members = data.map((member) => ({
        id: member._id,
        name: member.email,
      }));
      setMembers(_members);
      setSelectedMembers(_members);
      // setEmails(_members.map(email => ({ email, level: null })));
      // setIsLoading(false);
    } catch (error) {
      Logger.error('Error fetching workspace members:', error);
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    // const updateWidth = () => {
    //   setMaxWidth(`${(window.innerWidth * 5) / 12}px`);
    // };
    // window.addEventListener('resize', updateWidth);
    // updateWidth(); // initialize maxWidth

    const fetchData = async () => {
      await fetchWorkspaceMembers();
    };

    fetchData();

    return () => {
      // window.removeEventListener('resize', updateWidth);
      // setImageData([]);
    };
  }, []);

  const handleMemberSelection = (list) => {
    // const selectMember = selectedMembers.find( member => member._id === id);
    // if (selectMember) {
    //   return;
    // }

    // const memberSelected = members.find(member => member._id === id);
    setSelectedMembers(list);
  };

  const handleRemoveWorkspaceMember = (id) => {
    const newMembers = selectedMembers.filter((member) => member._id !== id);
    setSelectedMembers(newMembers);
  };

  const handleApprovalDetails = (name, value) => {
    setStepOneData({
      ...stepOneData,
      [name]: value,
    });
  };

  const handleDescription = (value) => {
    setDescription(value);
  };

  const addImageData = (newData) => {
    setImageData((preImageData) => [...preImageData, newData]);
  };

  const handleOpenUploadModal = () => {
    setOpenUploadModal(true);
  };

  const handleCloseUploadModal = () => {
    setOpenUploadModal(false);
  };

  const handleCloseSentModal = () => {
    // clear state
    setImageData([]);
    setApprovers([]);
    setStepOneData({
      title: '',
      dueDate: '',
      description: '',
      caption: '',
      link: '',
      imageUrl: {},
      selectedApprovers: [],
      workspaceID,
      userID: user?._id,
      workspaceMembers: members,
      platform,
      category,
      socialFormat,
      firebaseAuthUUID: user?.firebaseAuthUUID,
    });

    setOpenSentModal(false);
    history('/workspace/' + workspaceID);
  };

  const handleSetHtmlContent = (content) => {
    setHtmlContents([...htmlContents, content]);
  };

  const handleHtmlContentUrl = (url) => {
    setURL(url);
  };

  const handleNextSlide = () => {
    if (currentPage < (htmlContents.length || imageData.length) - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevSlide = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handlePlatformSelection = (iplatform, isocialFormat) => {
    iplatform && setPlatform(iplatform);
    isocialFormat && setSocialFormat(isocialFormat);
  };

  const handleCreateApprovalRequestEmail = async () => {
    // setIsLoading2(true);
    setIsSubmitting(true);
    const missingFields = validateRequest(
      stepOneData.title,
      stepOneData.dueDate,
      description,
      approvers,
      htmlContents
    );

    if (missingFields.length > 0) {
      toast({
        title: 'An error occurred.',
        description: `Please fill the following required fields: ${missingFields.join(
          ', '
        )}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      // setIsLoading2(false);
      setIsSubmitting(false);
      return;
    }

    try {
      const payload = {
        title: stepOneData.title,
        dueDate: stepOneData.dueDate,
        description: description,
        caption: stepOneData.caption,
        link: stepOneData.link,
        autoApprove: stepOneData.autoApprove,
        autoApproveDays: stepOneData.autoApprove ? stepOneData.autoApproveDays : 0,
        // htmlContent: (platform == 'landingPage' || platform == 'website') ? null : htmlContent,
        htmlContents,
        selectedApprovals: approvers,
        workspaceID: workspaceID,
        userID: user?._id,
        workspaceMembers: selectedMembers.map((member) => member.id),
        platform,
        category,
        socialFormat,
        _URL: (platform == 'landingPage' || platform == 'website') && URL,
        firebaseAuthUUID: user?.firebaseAuthUUID,
      };

      const response = await axios.post(
        '/api/workspace/createApprovalRequestEmail',
        payload
      );
      if (response.status === 201) {
        // alert("Approval Request is good.");
        setOpenSentModal(true);
        // history('/workspace/' + workspaceID)
      }
    } catch (error) {
      Logger.error('Error creating approval request:', error);
      toast({
        title: 'An error occurred.',
        description: `Error creating approval request: ${error}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    // setIsLoading2(false);
    setIsSubmitting(false);
  };

  const s3 = new AWS.S3();

  const moveVideoToBucket = async (videoUrl, targetBucket, type = 'video') => {
    // Extract the bucket name and key from the URL
    const sourceBucket = videoUrl.split('.s3.')[0].split('://')[1];
    const key = videoUrl.split('.com/')[1];

    const { ContentLength } = await s3
      .headObject({
        Bucket: sourceBucket,
        Key: key,
      })
      .promise();
    const fileSizeMB = ContentLength / (1024 * 1024); // Convert size to MB
    Logger.log('fileSizeMB ', fileSizeMB);

    // Copy the object to the new bucket
    await s3
      .copyObject({
        Bucket: targetBucket,
        CopySource: `${sourceBucket}/${key}`,
        Key: key,
      })
      .promise();

    // Delete the original object
    await s3
      .deleteObject({
        Bucket: sourceBucket,
        Key: key,
      })
      .promise();

    // Return the new URL and size
    return {
      type,
      url: `https://${targetBucket}.s3.${AWS.config.region}.amazonaws.com/${key}`,
      size: fileSizeMB, // Include the size in the return object
    };
  };

  const processFiles = async (type) => {
    const newVideoUrls = [];
    let additionalSizeMB = 0; // Initialize additional size

    // Process image files
    const imagesNew = await uploadToS3(
      imageData?.filter((f) => f.type === type),
      user,
      platform === 'document' ? platform : undefined
    );
    additionalSizeMB += imagesNew.reduce((acc, curr) => acc + curr.size, 0);

    // Move video files to a different bucket and accumulate sizes
    for (const file of imageData?.filter((f) => f.type === 'video')) {
      const moveResult = await moveVideoToBucket(file.data, 'swiftapprove-1');
      additionalSizeMB += moveResult.size; // Accumulate video file sizes
      newVideoUrls.push(moveResult);
    }

    // Now update the totalFileSize state once with the total additional size
    // Logger.log(additionalSizeMB);
    // setTimeout(() => {
    //   setTotalFileSize(additionalSizeMB);
    // }, 1000); // 1000 milliseconds = 1 second

    // Output the results
    const combinedUrls = imagesNew.concat(newVideoUrls);
    return [combinedUrls, additionalSizeMB];
  };

  const handleCreateApprovalRequest = async () => {
    if (category === 'web') {
      return handleCreateApprovalRequestEmail();
    }

    setIsSubmitting(true);

    // setIsLoading2(true);
    const missingFields = validateRequest(
      stepOneData.title,
      stepOneData.dueDate,
      description,
      approvers,
      imageData,
    );
  
    if (missingFields.length > 0) {
      toast({
        title: 'An error occurred.',
        description:
          missingFields[0] == 'Your content might still be uploading'
            ? 'Please wait, your content might still be uploading'
            : `Please fill the following required fields: ${missingFields.join(
                ', '
              )}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      // setIsLoading2(false);
      setIsSubmitting(false);
      return;
    }

    try {
      const imData = await processFiles(
        platform === 'document' ? socialFormat : 'image'
      );
      let validation = planValidationNewAppReq(user, imData);
      if (!validation.valid) {
        toast({
          title: 'Upgrade required',
          description: validation.msg,
          status: 'info',
          duration: 5000,
          isClosable: false,
        });
        // setIsLoading2(false);
        setIsSubmitting(false);
        return;
      }

      const payload = {
        title: stepOneData.title,
        dueDate: stepOneData.dueDate,
        description: description,
        caption: stepOneData.caption,
        link: stepOneData.link,
        autoApprove: stepOneData.autoApprove,
        autoApproveDays: stepOneData.autoApprove ? stepOneData.autoApproveDays : 0,
        imageUrl: imData[0],
        selectedApprovals: approvers,
        workspaceID: workspaceID,
        userID: user?._id,
        workspaceMembers: selectedMembers.map((member) => member.id),
        platform,
        category,
        socialFormat,
        firebaseAuthUUID: user?.firebaseAuthUUID,
        totalFileSize: imData[1],
        label: stepOneData.label,
      };

      const response = await axios.post(
        '/api/workspace/createApprovalRequest',
        payload
      );
      if (response.status === 201) {
        // alert("Approval Request is good.");
        setOpenSentModal(true);
        // history('/workspace/' + workspaceID)
      }
    } catch (error) {
      Logger.error('Error creating approval request:', error);
      toast({
        title: 'An error occurred.',
        description: `Error creating approval request: ${error}`,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
    // setIsLoading2(false);
    setIsSubmitting(false);
  };

  function removeItemAtIndex(index) {
    // First, check if the item to be removed is a video
    if (imageData[index]?.type === 'video') {
      // setIsPlaying(false); // Ensure the ReactPlayer stops playing the video

      // Allow a brief moment for the video to stop
      setTimeout(() => {
        proceedWithRemoval(index);
      }, 100);
    } else {
      // If it's not a video, proceed with removal immediately
      proceedWithRemoval(index);
    }
  }

  function proceedWithRemoval(index) {
    
    // Determine the new index based on the current state and the item being removed
    if(category !== 'web'){
      let newIndex;
      if (imageData?.length === 1) {
        newIndex = 0;
      } else if (imageData?.length > 1 && currentPage === 0) {
        newIndex = 0;
      } else {
        newIndex = currentPage - 1;
      }
  
      // Update the state based on the type of the item being removed
      if (imageData[index]?.type === 'video') {
        setNumVideosUploaded(numVideosUploaded - 1);
      } else {
        setNumImagesUploaded(numImagesUploaded - 1);
      }
  
      // Filter out the item being removed from imageData
      let imDat = imageData?.filter((_, idx) => idx !== index);
  
      // Update the states
      setImageDeleted(true);
      setCurrentImage(newIndex);
      setImageData(imDat);
    }else{
      let newIndex;
      if (htmlContents?.length === 1) {
        newIndex = 0;
      } else if (htmlContents?.length > 1 && currentPage === 0) {
        newIndex = 0;
      } else {
        newIndex = currentPage - 1;
      }
  
  
      // Filter out the item being removed from htmlContents
      let htCnts = htmlContents?.filter((_, idx) => idx !== index);
  
      // Update the states
      setCurrentPage(newIndex);
      setHtmlContents(htCnts);

    }
    
  }

  const handleAddLabel = (newLabelOption) => {
    const findLabel = labelOptions.find(
      (label) => label.value === newLabelOption.value
    );

    if (!findLabel) {
      setLabelOptions([...labelOptions, newLabelOption]);
    }
  };

  return (
    <PageWrapper title="Create Approval Request">
      <div className="flex flex-col sm:flex-row h-full">
        <div className="flex flex-col bg-primary-20 px-6 pt-6 w-[480px] gap-5 rounded-lg pb-10">
          <div>
            <h3 className="text-3xl font-semibold text-center">
              {step === 1
                ? 'Create Approval Request'
                : step === 2
                ? 'Add Approvers'
                : null}
            </h3>
          </div>
          <StepperIndicator
            steps={['Step one', 'Step two']}
            activeStep={step}
            handleStepSelect={handleStepChange}
          />
          <div className="flex flex-col grow gap-5 overflow-scroll no-scrollbar pb-10">
            {step === 1 && (
              <AddApprovalRequestDetails
                nextStep={handleNextAction}
                members={members}
                selectedMembers={selectedMembers}
                handleMembersSelect={handleMemberSelection}
                removeMember={handleRemoveWorkspaceMember}
                handleApprovalDetails={handleApprovalDetails}
                approvalRequestData={stepOneData}
                setDescription={handleDescription}
                isSubmitting={isSubmitting}
                handleAddLabel={handleAddLabel}
                labelOptions={labelOptions}
                platform={platform}
              />
            )}
            {step === 2 && (
              <AddApprovalRequestApprovers
                setApprovers={setApprovers}
                approvers={approvers}
                isSubmitting={isSubmitting}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col grow-[4] gap-6">
          <div className="flex justify-end">
            <button
              className={`bg-primary text-white rounded-lg py-2.5 px-5 flex text-base font-semibold`}
              onClick={handleCreateApprovalRequest}
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Sending...' : 'Send for review'}
            </button>
          </div>
          {!htmlContents.length && !imageData.length && (
            <NoContent handleContentUpload={handleOpenUploadModal} />
          )}

          {(!!htmlContents.length || !!imageData.length) && (
            <div
              className={`flex flex-col gap-3 px-12 ${
                isDesktopView ? '' : 'items-center'
              }`}
            >
              <div
                className={`flex px-5 pt-5 border rounded-lg min-w-[582px] max-w-[900px] h-[535px] relative`}
              >
                <div className="border w-full relative group overflow-hidden flex items-center justify-center">
                  {/* <div className="border w-full h-full relative group border overflow-hidden "> */}
                  {/* <div
                className={`flex px-5 pt-5 border rounded-lg min-w-[582px] max-w-[900px] h-[535px] relative ${
                  isDesktopView ? '' : 'w-[250px]'
                }`}
              >
                <div className="border w-full h-full relative group border overflow-hidden flex items-center justify-center"> */}
                  <CloseButton
                    onClick={() => !isSubmitting && removeItemAtIndex(currentPage)}
                    isVisible={!isSubmitting && true}
                  />
                  {imageData?.length > 0 &&
                  ['image', 'photo'].includes(
                    imageData[currentPage]?.type ??
                      imageData[currentPage]?.media
                  ) ? (
                    <img
                      src={
                        imageData[currentPage]?.data ??
                        imageData[currentPage]?.src
                      }
                      alt={`Post ${currentPage}`}
                      style={{
                        maxHeight: '50vh',
                        // minHeight: '50vh',
                        objectFit: 'contain',
                        boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)',
                      }}
                      className="h-full w-full"
                    />
                  ) : (imageData[currentPage]?.type ??
                      imageData[currentPage]?.media) === 'video' ? (
                    <div
                      ref={playerWrapperRef}
                      style={{ position: 'relative', width: 'fit-content' }}
                      className="border w-full"
                    >
                      <ReactPlayer
                        url={
                          imageData[currentPage]?.data ??
                          imageData[currentPage]?.src
                        }
                        // width="100%"
                        playing={false}
                        // height='100%'
                        style={{
                          // maxHeight: '50vh',
                          // minHeight: '50vh',
                          width: '100%',
                          objectFit: 'contain',
                          boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)',
                        }}
                        controls={true}
                      />
                    </div>
                  ) : (imageData[currentPage]?.type ??
                      imageData[currentPage]?.media) === 'pdf' ? (
                    <>
                      <PdfPreview
                        data={
                          imageData[currentPage]?.data ??
                          imageData[currentPage]?.src
                        }
                      />
                    </>
                  ) : category === 'web' && htmlContents[currentPage]?.src ? (
                    <div className="w-full h-full">
                      <iframe
                        id="test-iframe"
                        onLoad={scrubIframeDoc}
                        // src='https://swiftapprove.io'
                        loading="eager"
                        srcDoc={removeAnchorTagHref(
                          htmlContents[currentPage]?.src
                        )}
                        style={{
                          width: isDesktopView ? '200%' : '100%', // Increase width
                          height: isDesktopView ? '200%' : '100%',
                          // width: '200%',
                          // height: "200%",
                          border: 'none',
                          margin: '0 auto',
                          // boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.1)',
                          transform: isDesktopView
                            ? 'scale(0.5)'
                            : 'scale(1.0)',
                          transformOrigin: '0 0',
                          position: 'relative',
                          // pointerEvents: 'none',
                          overflow: 'scroll',
                        }}
                      ></iframe>
                    </div>
                  ) : (
                    <>
                      <DocPreview
                        data={imageData[currentPage]?.data}
                        url={imageData[currentPage]?.src}
                      />
                    </>
                  )}
                </div>
                {currentPage > 0 && (
                  <div
                    className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full absolute rotate-180 -left-12 bottom-2/4 cursor-pointer"
                    onClick={!isSubmitting && handlePrevSlide}
                  >
                    <NextArrow />
                  </div>
                )}
                {currentPage <
                  (htmlContents.length || imageData.length) - 1 && (
                  <div
                    className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full absolute -right-12 bottom-2/4 cursor-pointer"
                    onClick={!isSubmitting && handleNextSlide}
                  >
                    <NextArrow />
                  </div>
                )}
              </div>
              <div className="flex w-full justify-center gap-1.5 overflow-scroll no-scrollbar">
                {imageData?.map((_, idx) => (
                  <div
                    // className="w-2.5 h-2.5 rounded-full bg-primary"
                    key={idx}
                    onClick={() => !isSubmitting && handleDotClick(idx)}
                    className={`w-2.5 h-2.5 rounded-full bg-primary cursor-pointer ${
                      currentImage === idx ? 'bg-primary' : 'bg-gray-400'
                    }`}
                  ></div>
                ))}
              </div>
            </div>
          )}

          {(!!htmlContents.length || !!imageData.length) && (
            <div className="flex justify-center">
              <div
                className="flex justify-center text-primary gap-2 cursor-pointer items-center w-fit "
                onClick={!isSubmitting && handleOpenUploadModal}
              >
                <Plus className="stroke-primary-default" /> Upload more content
              </div>
            </div>
          )}
          {/* </div> */}
        </div>
      </div>
      <UploadContentModal
        isOpen={openUploadModal}
        closeModal={handleCloseUploadModal}
        category={category}
        setHtmlContent={handleSetHtmlContent}
        setURL={handleHtmlContentUrl}
        setPlatform={handlePlatformSelection}
        platform={platform}
        addImageData={addImageData}
      />

      <ApprovalCreatedModal
        isOpen={openSentModal}
        closeModal={handleCloseSentModal}
      />
    </PageWrapper>
  );
};

export default CreateApprovalR;
