import { useEffect, useRef, useState } from 'react';

const NotificationMenu = ({seen, clear, handleSeen }) => {
  const menuRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleMarkAsRead = async () => {
    await handleSeen();
    setShowMenu(false);
  }

  const handleArchive = async () => {
    await clear();
    setShowMenu(false);
  }

  return (
    <div
      className="w-6 h-6 relative text-right self-end font-bold group/menu cursor-pointer"
      ref={menuRef}
    >
      <p onClick={handleShowMenu}>...</p>
      {showMenu && (
        <div className="absolute top-full right-0 w-40 bg-white border border-gray-200 rounded-md shadow-lg text-xs font-semibold z-10">
          {!seen ? <div className="w-full h-10 flex items-center justify-center border-b border-gray-200 " onClick={handleMarkAsRead}>
            Mark as Read
          </div>: null}
          <div className="w-full h-10 flex items-center justify-center border-b border-gray-200 " onClick={handleArchive}>
            Archive
          </div>
          {/* <div className="w-full h-10 flex items-center justify-center border-b border-gray-200 ">
            Turn off notification
          </div> */}
        </div>
      )}
    </div>
  );
};

export default NotificationMenu;
